<template>
  <div :class="$style.auth">
    <div class="pt-5 pb-5 d-flex align-items-end mt-auto">
      <img src="resources/images/air-logo.png" alt="AIR UI Logo" />
      <div class="air__utils__logo__text">
        <div class="air__utils__logo__name text-uppercase text-dark font-size-21">AIR UI</div>
        <div class="air__utils__logo__descr text-uppercase font-size-12 text-gray-6">Admin template</div>
      </div>
    </div>
    <div class="pl-5 pr-5 pt-5 pb-5 bg-white text-center" :class="$style.container">
      <div class="text-dark font-size-30 mb-4">Account Locked</div>
      <div class="air__utils__avatar air__utils__avatar--size64 d-inline-block mb-2">
        <img src="resources/images/avatars/2.jpg" alt="Mary Stanform" />
      </div>
      <div class="font-weight-bold font-size-18 text-dark mb-4">Mary Stanform</div>
      <a-form class="mb-4" :form="form">
        <a-form-item>
          <a-input
            placeholder="Password"
            v-decorator="['password', {rules: [{ required: true, message: 'Please input your Password!' }]}]"
          />
        </a-form-item>
        <button
          type="button"
          class="text-center btn btn-success w-100 font-weight-bold font-size-18"
        >Log In</button>
      </a-form>
    </div>
    <div class="text-center font-size-18 pt-4 mb-auto">
      Don't have an account?
      <router-link to="/system/register" class="font-weight-bold text-blue text-underlined">
        <u>Sign Up</u>
      </router-link>
    </div>
    <div class="mt-auto pb-5 pt-5">
      <div class="text-gray-4 text-center">© 2020 Zeon Solutions. Todos os direitos reservados.</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AirLockscreen',
  data: function () {
    return {
      form: this.$form.createForm(this),
    }
  },
}
</script>
<style lang="scss" module>
@import "@/components/system/Auth/style.module.scss";
</style>
