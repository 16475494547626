<template>
  <div>
    <air-lockscreen />
  </div>
</template>
<script>
import AirLockscreen from '@/components/system/Auth/Lockscreen'
export default {
  components: {
    AirLockscreen,
  },
}
</script>
